<template>
  <v-card class="card text-center font-raleway-regular">
    <h3 class="card-text mb-8 font-family-raleway-bold">{{$t("create_campaign_page.choose_plan_step_title")}}</h3>
    <div class="justify-center d-flex font-family-raleway-medium">
      <span
        :class="
          planType === 'monthlyFee'
            ? 'color-blue font-weight-bold'
            : 'cursor-pointer'
        "
        @click="planType = 'monthlyFee'"
        >{{$t("create_campaign_page.plan_type_monthly_text")}}</span
      >
      <v-divider vertical class="mx-5" style="height: 25px" />
      <div>
        <span
          :class="
            planType === 'annualFee'
              ? 'color-blue font-weight-bold'
              : 'cursor-pointer'
          "
          @click="planType = 'annualFee'"
          >{{$t("create_campaign_page.plan_type_annual_text")}}</span
        >
        <p class="mt-1">
          <span class="discount-badge">SAVE 15%</span>
        </p>
      </div>
    </div>
    <v-row class="px-5 mt-2">
      <v-col
        md="4"
        v-for="{ id, name, annualFee, monthlyFee } of allPlans"
        :key="id"
      >
        <v-card class="plans-card pa-7">
          <p style="font-weight: 600">{{ name }}</p>
          <h2 class="font-family-raleway-bold">${{ planType === "annualFee" ? annualFee/12 : monthlyFee }}</h2>
          <!--          <p><span class="discount-badge">SAVE 15%</span></p>-->
          <p v-if="planType === 'annualFee'">
            <span class="discount-badge">SAVE 15%</span>
          </p>
          <v-divider class="mt-4"/>
          <p class="mt-6 font-family-raleway-medium">
            Billed {{ planType === "annualFee" ? $t("create_campaign_page.plan_type_annual_text") : $t("create_campaign_page.plan_type_monthly_text") }}
          </p>
          <p class="py-4 font-family-raleway-medium">
            {{ $t('create_campaign_page.choose_plan_card_description') }}
          </p>
          <v-btn
            class="button text-none font-family-raleway-medium"
            height="48"
            width="100%"
            @click="choosePlan(id, name, annualFee, monthlyFee)"
            >{{ $t("create_campaign_page.choose_plan_button") }}</v-btn>
        </v-card>
      </v-col>
    </v-row>
    <v-btn
      style="background-color: white; box-shadow: none"
      @click="handleClickBack"
      class="font-family-raleway-medium mt-10 text-decoration-underline text-capitalize"
      >{{ $t("back_button") }}
    </v-btn>
    <cancel-modal
      :cancel="cancel"
      :handle-click-cancel="handleClickBack"
      :cancel-modal="handleCancelModal"
    />
    <make-sure-chosen-plan
      :dialog="dialog"
      @closeModal="dialog = false"
      :plan="chosenPlan"
    />
  </v-card>
</template>
<script>
import { plansService } from "@/services/plans";

export default {
  name: "ChoosePlan",
  components: {
    MakeSureChosenPlan: () => import("@/components/dialogs/MakeSureChosenPlan"),
    CancelModal: () => import("@/components/MainLanding/Modals/CancelModal")
  },
  data() {
    return {
      planType: "monthlyFee",
      cancel: false,
      dialog: false,
      chosenPlan: {},
      allPlans: [],
    };
  },
  async created() {
    const response = await plansService.getAllPlans();
    const { data } = response;
    this.allPlans = data;
  },
  methods: {
    handleClickBack() {
      this.$router.push("review-company");
    },
    handleCancelModal() {
      this.cancel = false;
    },
    choosePlan(planId, planName, annualFee, monthlyFee) {
      this.chosenPlan = {
        planType: this.planType,
        planName,
        planId,
        planAmount: this.planType === "annualFee" ? annualFee : monthlyFee,
      };
      this.dialog = true;
    },
  },
};
</script>
